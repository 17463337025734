$ui-base-color: #2f3436;
$ui-primary-color: hsl(205deg, 30%, 65%);
$ui-secondary-color: hsl(220deg, 25%, 88%);
$ui-highlight-color: #5c90c7;
$ui-button-background-color: #4a73a0;
$ui-button-focus-background-color: #3d6085;
$ui-button-focus-outline-color: #7aa3d1;
$ui-button-background-color: #4a73a0;
$ui-button-focus-background-color: #3d6085;
$ui-button-focus-outline-color: #7aa3d1;
$ui-button-secondary-color: #4a73a0;
$ui-button-secondary-border-color: #4a73a0;
$ui-button-secondary-focus-border-color: #7aa3d1;
$ui-button-secondary-focus-color: #7aa3d1;
$ui-button-tertiary-color: #7aa3d1;
$ui-button-tertiary-border-color: #7aa3d1;
$ui-button-tertiary-focus-background-color: #3d6085;
$ui-button-icon-hover-background-color: rgba(74, 115, 160, 40%);

@import 'application';
